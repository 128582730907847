import pick from 'lodash/pick'
import List from '../../../pages/CRM/Lead/List'
import * as filters from '../../../pages/CRM/Lead/Filters'
import * as commonFilters from '../../../pages/CRM/common/Filters'
import {appointmentFields as appointments} from './appointments'
import {getCountryConfig} from '../../../../config/country'
import AdId from '../../../pages/CRM/Lead/AdId'

export const leadSort = [{field: 'createdAt', order: 'DESC'}]

export const {leadsAdId} = getCountryConfig()

const appointmentFields = pick(appointments, [
  'role',
  'appointment',
  'booking',
  'assignee',
  'status',
  'location',
  'created',
  'participants',
  'comment'
])

export const responsible = {
  key: 'responsibleName',
  label: 'task.responsible.label',
  type: 'string',
  filter: filters.Responsible
}

export const leadFields = {
  carInfo: {
    type: 'composite',
    label: 'carInfo',
    key: 'carInfo',
    separator: '\n',
    layout: 'rows',
    children: [
      {
        label: 'make / model',
        key: 'make_model',
        type: 'composite',
        layout: 'inline',
        separator: ' / ',
        children: [
          {
            type: 'string',
            key: 'make',
            separator: ' / ',
            label: 'global.make.label'
          },
          {
            type: 'string',
            key: 'model',
            label: 'global.model.label'
          }
        ]
      },
      {
        label: 'trim / year',
        key: 'trim_year',
        type: 'composite',
        layout: 'inline',
        separator: ' / ',
        children: [
          {
            type: 'string',
            key: 'trim',
            separator: ' / ',
            label: 'global.trim.label'
          },
          {
            type: 'number',
            key: 'year',
            label: 'global.year.label',
            separator: ' / ',
            format: false
          },
          {
            key: 'plate',
            label: 'global.plate.label',
            type: 'string',
            columnName: 'plate'
          }
        ]
      }
    ]
  },
  mileage: {
    type: 'composite',
    layout: 'rows',
    separator: '\n',
    key: 'mileage_transmission',
    label: 'mileage_transmission',
    children: [
      {
        type: 'number',
        key: 'mileage',
        label: 'global.mileage.label',
        isSortable: true
      },
      {
        type: 'string',
        key: 'transmission',
        label: 'global.transmission.label'
      }
    ]
  },
  adId: {
    type: 'custom',
    label: 'global.adId.label',
    key: 'adId',
    Component: AdId
  },
  VINnumber: {
    type: 'composite',
    label: 'global.VINnumber.label',
    key: 'global.VINnumber',
    layout: 'rows',
    children: [
      {
        key: 'VINnumber',
        label: 'global.VINnumber.label',
        type: 'string',
        columnName: 'VINnumber',
        isSortable: false
      }
    ]
  },
  contact: {
    type: 'composite',
    label: 'contact',
    key: 'contact',
    layout: 'rows',
    link: {
      base: 'crm/contacts/detail',
      key: 'contact.id'
    },
    children: [
      {
        type: 'composite',
        key: 'contact.name',
        label: 'contact.name.label',
        separator: ' ',
        children: [
          {
            key: 'contact.firstname',
            label: 'contact.firstname.label',
            separator: ' ',
            type: 'string',
            isSortable: false
          },
          {
            key: 'contact.lastname',
            label: 'contact.lastname.label',
            type: 'string',
            isSortable: false
          }
        ]
      },
      {
        key: 'contact.phone',
        label: 'contact.phone.label',
        type: 'string',
        columnName: 'phone',
        isSortable: false,
        filter: commonFilters.PhoneValidation,
      }
    ]
  },
  inspectionCity: {
    key: 'inspectionCity',
    type: 'string',
    label: 'lead.city.label',
    columnName: 'leadCity',
    isSortable: false
  },
  status: {
    type: 'composite',
    separator: '\n',
    layout: 'rows',
    key: 'status_rejectionReasons',
    label: 'status_rejectionReasons',
    children: [
      {
        type: 'string',
        key: 'status',
        label: 'lead.status.label',
        filter: filters.LeadStatus
      },
      {
        type: 'composite',
        key: 'rejectionReasons_discardedReason',
        separator: ' / ',
        children: [
          {
            type: 'string',
            key: 'rejectionReasons',
            label: 'lead.rejectionReasons.label',
            filter: filters.RejectionReasons
          },
          {
            type: 'string',
            key: 'discardedReason',
            label: 'lead.discardedReason.label',
            filter: filters.DiscardedReason
          }
        ]
      }
    ]
  },
  prices: {
    type: 'composite',
    key: 'prices',
    separator: '\n',
    label: 'prices',
    layout: 'rows',
    children: [
      {
        type: 'number',
        key: 'price',
        label: 'lead.price.label',
        currency: true
      },
      {
        type: 'composite',
        key: 'min-max',
        label: 'min-max',
        children: [
          {
            key: 'minPrice',
            label: 'lead.minprice.label',
            type: 'number',
            currency: true,
            separator: ' - '
          },
          {
            key: 'maxPrice',
            label: 'lead.maxprice.label',
            type: 'number',
            currency: true
          }
        ]
      }
    ]
  },
  selfInspectionPrice: {
    type: 'number',
    key: 'selfInspectionPrice',
    columnName: 'selfInspectionPrice',
    label: 'lead.selfInspectionPrice.label',
    currency: true,
    isFilterable: false
  },
  selfInspectionPriceExists: {
    type: 'boolean',
    key: 'selfInspectionPriceExists',
    columnName: 'selfInspectionPriceExists',
    label: 'lead.selfInspectionPriceExists.label',
    isFilterable: true
  },
  source: {
    type: 'composite',
    label: 'source',
    key: 'source',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        type: 'composite',
        key: 'leadSource_referralCode',
        children: [
          {
            type: 'string',
            key: 'leadSource',
            label: 'lead.leadSource.label',
            separator: ' / ',
            columnName: 'leadSource',
            filter: filters.LeadSource,
            filterName: 'leadSource'
          },
          {
            type: 'string',
            key: 'referralCode',
            label: 'lead.referralCode.label'
          }
        ]
      },
      {
        type: 'composite',
        key: 'serviceType_inspectionType',
        separator: ' / ',
        children: [
          {
            type: 'string',
            key: 'serviceType',
            label: 'lead.serviceType.label',
            separator: ' / ',
            filter: filters.ServiceType
          },
          {
            type: 'string',
            key: 'inspectionType',
            label: 'lead.inspectionType.label',
            filter: filters.InspectionType
          }
        ]
      }
    ]
  },
  owner: {
    type: 'composite',
    label: 'source',
    key: 'source',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        type: 'string',
        key: 'ownerName',
        label: 'lead.ownerName.label'
      },
      {
        type: 'string',
        key: 'ownerPhone',
        label: 'lead.ownerPhone.label'
      }
    ]
  },
  created: {
    type: 'composite',
    label: 'created',
    layout: 'rows',
    key: 'createdAt',
    children: [
      {
        type: 'date',
        key: 'createdAt',
        label: 'global.createdAt.label',
        time: true
      },
      {
        type: 'email',
        key: 'createdByUserEmail',
        label: 'global.createdBy.label',
        cut: true,
        columnName: 'email'
      }
    ]
  },
  updated: {
    type: 'composite',
    label: 'updated',
    layout: 'rows',
    key: 'updatedAt',
    columnName: 'updated',
    children: [
      {
        type: 'date',
        key: 'updatedAt',
        label: 'global.updatedAt.label',
        time: true
      },
      {
        type: 'email',
        key: 'updatedByUserEmail',
        label: 'global.updatedBy.label',
        cut: true,
        columnName: 'updatedByUserEmail'
      }
    ]
  },
  selfInspectionExists: {
    key: 'selfInspectionExists',
    label: 'filter.placeholder.SelfInspection',
    type: 'boolean',
    columnName: 'selfInspectionExists',
    isFilterable: true
  },

  appointments: {
    type: 'table',
    label: 'appointments',
    key: 'appointments',
    fields: appointmentFields
  },
  sourcePathVariant: {
    type: 'string',
    key: 'sourcePathVariant',
    label: 'lead.sourcePathVariant.label',
    columnName: 'sourcePathVariant',
    filterName: 'sourcePathVariant'
  }
}

export const leadSelfInspectionFields = [
  {
    key: 'question',
    label: 'global.question.label',
    type: 'string',
    isSortable: false
  },
  {
    key: 'answer',
    label: 'global.answer.label',
    type: 'string',
    isSortable: false
  }
]

export default {
  path: 'leads',
  label: 'menu.crm.lead',
  component: List,
  sort: leadSort,
  fields: {
    ...leadFields,
    responsible
  },
  permissions: {
    entity: 'web.admin.ui.crm.leads',
    types: ['READ']
  }
}
