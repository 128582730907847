import {Component} from 'react'
import styled from 'styled-components'
import {translate} from 'react-i18next'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {withOktaAuth} from '@okta/okta-react'
import cookies from 'browser-cookies'

import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import {handleOAuthLogin, setOAuthParams} from '../../store/auth/actions'
import {
  isSubmitting,
  getError,
  getCountryName
} from '../../store/auth/selectors'
import {oauthLoginTypes} from '../../store/auth/types'
import {getCountryCode} from '../../store/config/selectors'
import {getGoogleOAuthUrl} from '../../api/auth/requests'

const LogoWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  width: 143px;
  height: 27px;
`

const CountryName = styled.span`
  color: #7f9799;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
`

const Container = styled(Paper)`
  width: 380px;
  height: auto;
  padding: 24px;
  text-align: center;
`

const Grid = styled.div`
  margin: 8px auto;
  width: 100%;
`

const Bold = styled.span`
  font-weight: 500;
  color: #3f51b5;
`

const ButtonWrapper = styled.div`
  width: 380px;
  margin-bottom: 24px;
`

const StyledButton = styled(Button)`
  margin-top: 16px !important;
  width: 100%;
`

const Anchor = styled.a`
  margin-top: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  font-size: 11px;
  height: 13px;
  color: #3a77ff;
  text-decoration: none;
  cursor: pointer;
`

class OAuth extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      type: this.props.type
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)

    this.props.setOAuthParams({
      countryCode: this.props.countryCode,
      redirectURI: query.get('redirect_uri'),
      state: query.get('state'),
      clientId: query.get('client_id'),
      clientSecret: query.get('client_secret')
    })
    this.setOAuthParams()
  }

  setOAuthParams = () => {
    const query = new URLSearchParams(this.props.location.search)
    localStorage.setItem(
      'oAuthParams',
      JSON.stringify({
        countryCode: this.props.countryCode,
        redirectURI: query.get('redirect_uri'),
        state: query.get('state'),
        clientId: query.get('client_id'),
        clientSecret: query.get('client_secret')
      })
    )
  }

  handleGoogleLogin = (type = 'global') => {
    this.setOAuthParams()
    cookies.set('google-client-type', type)
    window.sessionStorage.setItem('googleRedirect', '/k2-login/callback')
    getGoogleOAuthUrl({redirectUrl: '/k2-login/callback'}).then((res) => {
      const {authUrl} = res?.data || {}
      if (authUrl) {
        window.location.href = authUrl
      }
    })
    this.setState({
      type: oauthLoginTypes.GOOGLE
    })
  }

  handleOktaLogin = async () => {
    this.setOAuthParams()
    this.props.oktaAuth.signInWithRedirect()
    this.props.handleOAuth({type: oauthLoginTypes.OKTA})
    this.setState({
      type: oauthLoginTypes.OKTA
    })
  }

  handleSubmit = () => {
    this.setState(
      {
        type: oauthLoginTypes.MANUAL
      },
      () => {
        this.props.handleOAuth({
          email: this.state.email,
          password: this.state.password,
          type: oauthLoginTypes.MANUAL
        })
      }
    )
  }

  handleChange = (e) => {
    const {name, value} = e.target

    this.setState({[name]: value})
  }

  handleCountryChange = () => {
    window.location.href = `https://k2admin.olxautos.com/${window.location.search}`
  }

  render() {
    const {error} = this.props

    return (
      <FormWrapper>
        <LogoWrapper>
          <Logo src='/public/images/olxautos.png' />
          <CountryName>{this.props.countryName}</CountryName>
        </LogoWrapper>
        <ButtonWrapper>
          <StyledButton
            variant='contained'
            color='primary'
            onClick={() => this.handleGoogleLogin('local')}
          >
            {this.props.t('login.button.login.google')} (Local)
          </StyledButton>
          <StyledButton
            color='primary'
            variant='contained'
            onClick={() => this.handleGoogleLogin('global')}
          >
            {this.props.t('login.button.login.google')}
          </StyledButton>
          {error !== null && this.state.type === oauthLoginTypes.GOOGLE && (
            <Typography color='error' gutterBottom align='left'>
              {error}
            </Typography>
          )}
        </ButtonWrapper>
        <ButtonWrapper>
          <StyledButton
            color='primary'
            variant='contained'
            onClick={this.handleOktaLogin}
          >
            LOGIN WITH OKTA
          </StyledButton>
          {error !== null && this.state.type === oauthLoginTypes.OKTA && (
            <Typography color='error' gutterBottom align='left'>
              {error}
            </Typography>
          )}
        </ButtonWrapper>
        <Container elevation={2}>
          <span>{this.props.t('oauth.login.welcomeMessage')} :)</span>
          <p>
            <Bold>{this.props.t('oauth.login.logInHead')}</Bold>
            {` ${this.props.t('oauth.login.logInRest')}`}.
          </p>
          <Grid>
            <TextField
              fullWidth
              label={this.props.t('userProfile.field.email')}
              value={this.state.email}
              onChange={this.handleChange}
              error={error}
              className='internalLoginEmail'
              name='email'
            />
          </Grid>
          <Grid>
            <TextField
              fullWidth
              type='password'
              label={this.props.t('userProfile.field.password')}
              value={this.state.password}
              onChange={this.handleChange}
              error={error}
              name='password'
              className='internalLoginPassword'
            />
          </Grid>
          <Grid>
            {error !== null && this.state.type === oauthLoginTypes.MANUAL && (
              <Typography color='error' gutterBottom align='left'>
                {error}
              </Typography>
            )}
            <StyledButton
              variant='contained'
              color='primary'
              type='submit'
              onClick={this.handleSubmit}
              disabled={
                this.props.isSubmitting ||
                !this.state.email ||
                !this.state.password
              }
            >
              {this.props.t('login.button.login')}
            </StyledButton>
          </Grid>
        </Container>
        <Anchor onClick={this.handleCountryChange}>
          {this.props.t('k2.changeCountry')}
        </Anchor>
      </FormWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting(state),
  error: getError(state),
  countryName: getCountryName(),
  countryCode: getCountryCode(state)
})

const mapDispatchToProps = (dispatch) => ({
  handleOAuth: (payload) => dispatch(handleOAuthLogin(payload)),
  setOAuthParams: (payload) => dispatch(setOAuthParams(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate()(withRouter(withOktaAuth(OAuth))))
