import {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {Typography, IconButton} from '@material-ui/core'
import {IconButtonLink} from '../../Links/IconButtonLink'
import {Input, Settings} from '@material-ui/icons'
import {logout} from '../../../store/auth/actions'
import {toggleMenu} from '../../../store/global/actions'
import {withOktaAuth} from '@okta/okta-react'

class UserWidget extends Component {
  logout = async () => {
    if (this.props.authState && this.props.authState.isAuthenticated) {
      await this.props.oktaAuth.signOut()
    }
    this.props.logout()
  }

  render() {
    const {username} = this.props

    if (username === null) {
      return null
    }

    return username === null ? null : (
      <div className='userBox'>
        <Typography type='body2' color='inherit'>
          {username}
        </Typography>
        <IconButton onClick={this.logout} className='userBox__button'>
          <Input />
        </IconButton>
        <IconButtonLink to='/settings' className='userBox__button'>
          <Settings />
        </IconButtonLink>
      </div>
    )
  }
}

const mapStateToProps = ({authReducer}) => {
  const {isAuthenticated} = authReducer

  if (!isAuthenticated) {
    return {username: null}
  }

  const {username, email, user} = isAuthenticated
  const {firstname = '', lastname = ''} = user || {}

  return {
    username: username || `${firstname}  ${lastname}`.trim() || email || ''
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(toggleMenu(false))
      dispatch(logout())
    }
  }
}

export default compose(
  withRouter,
  withOktaAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(UserWidget)
