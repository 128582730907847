import {Route} from 'react-router-dom'
import ConfiguredRoute from './ConfiguredRoute'
import Login from './Login/Login'
import OktaLoginCallback from './Login/OktaLoginCallback' // added to for custom token handling
import Auth from '../components/providers/Auth'
import {UserConsumer} from '../components/providers/withAccessManagement'
import OAuth from './Login/OAuth'

export const RouteContainer = (props) => {
  return (
    <Auth {...props}>
      <ConfiguredRoute {...props} className='page'>
        <Route component={OktaLoginCallback} exact path='/login/callback' />
        <Route component={OAuth} exact path='/k2-login' />
        <Route component={OktaLoginCallback} exact path='/k2-login/callback' />
        <Route component={Login} />
      </ConfiguredRoute>
    </Auth>
  )
}

export default (props) => UserConsumer(RouteContainer, props)
