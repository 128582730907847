import {Appointments} from '../../../pages/CRM'
import * as filters from '../../../pages/CRM/Appointment/Filters'
import * as leadFilters from '../../../pages/CRM/Lead/Filters'
import * as commonFilters from '../../../pages/CRM/common/Filters'
import {Comment} from '@material-ui/icons'
import omit from 'lodash/omit'

export const appointmentFields = {
  role: {
    type: 'string',
    key: 'role',
    label: 'contact.role.label',
    columnName: 'role',
    prefix: 'appointment.participants.role'
  },
  contact: {
    type: 'composite',
    label: 'contact',
    key: 'contact',
    layout: 'rows',
    link: {
      base: 'crm/contacts/detail',
      key: 'lead.contact.id'
    },
    children: [
      {
        type: 'composite',
        key: 'lead.contact.name',
        label: 'contact.name.label',
        separator: ' ',
        children: [
          {
            key: 'lead.contact.firstname',
            label: 'contact.firstname.label',
            separator: ' ',
            type: 'string',
            isSortable: false
          },
          {
            key: 'lead.contact.lastname',
            label: 'contact.lastname.label',
            type: 'string',
            isSortable: false
          }
        ]
      },
      {
        key: 'lead.contact.phone',
        label: 'contact.phone.label',
        type: 'string',
        isSortable: false,
        columnName: 'phone',
        filter: commonFilters.PhoneValidation
      },
      {
        key: 'lead.contact.email',
        label: 'contact.email.label',
        type: 'string',
        isSortable: false,
        columnName: 'email'
      }
    ]
  },
  carInfo: {
    type: 'composite',
    label: 'lead',
    key: 'lead',
    separator: '\n',
    layout: 'rows',
    children: [
      {
        label: 'make / model',
        key: 'make_model',
        type: 'composite',
        layout: 'inline',
        separator: ' / ',
        children: [
          {
            type: 'string',
            key: 'lead.make',
            separator: ' / ',
            label: 'global.make.label',
            isSortable: false
          },
          {
            type: 'string',
            key: 'lead.model',
            label: 'global.model.label',
            isSortable: false
          }
        ]
      },
      {
        label: 'trim / year',
        key: 'trim_year',
        type: 'composite',
        layout: 'inline',
        separator: ' / ',
        children: [
          {
            type: 'string',
            key: 'lead.trim',
            separator: ' / ',
            label: 'global.trim.label',
            isSortable: false
          },
          {
            type: 'number',
            key: 'lead.year',
            label: 'global.year.label',
            format: false,
            isSortable: false
          }
        ]
      },
      {
        key: 'lead.plate',
        label: 'global.plate.label',
        type: 'string',
        isSortable: false
      },
      {
        key: 'lead.status',
        label: 'lead.status.label',
        columnName: 'lead.status',
        type: 'string',
        options: 'leadStatus',
        filter: leadFilters.LeadStatus,
        filterName: 'lead.status'
      }
    ]
  },
  appointment: {
    label: 'appointment',
    key: 'appointment',
    type: 'composite',
    layout: 'rows',
    children: [
      {
        key: 'inspectionPointName',
        label: 'appointment.inspectionPointName.label',
        type: 'string',
        filter: filters.InspectionPointName
      },
      {
        key: 'address',
        label: 'appointment.address.label',
        type: 'string',
        isSortable: false,
        filterName: 'appointmentAddress'
      },
      {
        type: 'composite',
        key: 'slot',
        label: 'appointment.slot.label',
        separator: ', ',
        children: [
          {
            key: 'date',
            label: 'appointment.date.label',
            type: 'date',
            separator: ' ',
            convert: false,
            filter: filters.AppointmentSlot
          },
          {
            key: 'time',
            type: 'time',
            label: 'appointment.time.label'
          }
        ]
      },
      {
        key: 'bookingId',
        label: 'appointment.bookingId.label',
        type: 'string'
      }
    ]
  },
  purchaseCoordinator: {
    type: 'composite',
    key: 'purchaseCoordinator',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        key: 'purchaseCoordinator',
        label: 'appointment.purchaseCoordinator.label',
        type: 'string',
        isFilterable: false
      },
      {
        key: 'purchaseCoordinatorPhone',
        label: 'appointment.purchaseCoordinatorPhone.label',
        type: 'string',
        isFilterable: false
      }
    ]
  },
  assignee: {
    type: 'composite',
    key: 'assignee_appointmentType',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        key: 'assignedToName',
        label: 'appointment.assignedTo.label',
        type: 'string'
      },
      {
        key: 'type',
        label: 'appointment.type.label',
        type: 'string',
        filter: filters.AppointmentType
      }
    ]
  },
  leadSourceServiceType: {
    type: 'composite',
    key: 'leadSourceServiceType',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        key: 'lead.leadSource',
        label: 'lead.leadSource.label',
        type: 'string',
        filter: filters.LeadSource,
        isSortable: false,
        columnName: 'leadSource',
        filterName: 'leadSource'
      },
      {
        label: 'serviceType / inspectionType',
        key: 'serviceType_inspectionType',
        type: 'composite',
        layout: 'inline',
        separator: ' / ',
        children: [
          {
            type: 'string',
            key: 'lead.serviceType',
            separator: ' / ',
            label: 'lead.serviceType.label',
            isSortable: false
          },
          {
            type: 'string',
            key: 'lead.inspectionType',
            label: 'lead.inspectionType.label',
            isSortable: false
          }
        ]
      },
      {
        key: 'lead.responsibleName',
        label: 'task.responsible.label',
        type: 'string',
        columnName: 'leadResponsibleName',
        isSortable: false,
        filter: filters.Responsible
      }
    ]
  },
  status: {
    key: 'status_cancellation',
    label: 'global.status.label',
    type: 'composite',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        type: 'composite',
        key: 'cancelled_reason',
        separator: ' ',
        children: [
          {
            key: 'status',
            label: 'appointment.status.label',
            type: 'string',
            separator: ' ',
            filter: filters.AppointmentStatus
          },
          {
            type: 'composite',
            key: 'cancelled_rejection_reason',
            separator: ' - ',
            parentheses: true,
            children: [
              {
                key: 'cancellationReason',
                label: 'appointment.cancellationReason.label.short',
                filterLabel: 'appointment.cancellationReason.label',
                type: 'string',
                filter: filters.CancellationReason
              },
              {
                key: 'rejectionReasons',
                label: 'appointment.rejectionReasons.label.short',
                filterLabel: 'appointment.rejectionReasons.label',
                type: 'string',
                filter: filters.RejectionReasons,
                columnName: 'rejectionReasons'
              }
            ]
          }
        ]
      },
      {
        key: 'cancelledInStatus',
        label: 'appointment.cancelledInStatus.label',
        type: 'string',
        filter: filters.CancelledInStatus
      }
    ]
  },
  participants: {
    type: 'popover',
    label: 'appointment.participants.label',
    key: 'participants',
    layout: 'rows',
    placeholder: (props) =>
      props.data && props.data.length
        ? props.t('appointment.participants.placeholder')
        : '',
    getDataToRender: (props) => {
      if (!props.data) {
        return []
      }

      return props.data.map((participant) => ({
        label: `${participant.firstname} ${participant.lastname} - ${props.t(
          `appointment.participants.role.${participant.role}`
        )}`,
        link: `/crm/contacts/detail/${participant.id}`
      }))
    },
    children: []
  },
  booking: {
    type: 'composite',
    key: 'booking_callStatus',
    layout: 'rows',
    separator: '\n',
    children: [
      {
        key: 'bookingSource',
        label: 'appointment.bookingSource.label',
        type: 'string',
        filter: filters.BookingSource,
        filterName: 'bookingSource',
        columnName: 'bookingSource'
      },
      {
        key: 'callStatus',
        columnName: 'callStatus',
        label: 'appointment.callStatus.label',
        type: 'string',
        filter: filters.CallStatus,
        filterName: 'callStatus'
      }
    ]
  },
  comment: {
    type: 'composite',
    label: 'comments',
    layout: 'rows',
    key: 'booking_comments',
    link: {
      base: 'appointment/comment',
      key: 'id',
      isRelative: true
    },
    children: [
      {
        type: 'string',
        label: 'appointment.comments.label',
        key: 'comments',
        overrideIcon: <Comment color='disabled' />
      }
    ]
  },
  location: {
    type: 'composite',
    label: 'location',
    key: 'location',
    layout: 'rows',
    children: [
      {
        key: 'lat',
        label: 'appointment.lat.label',
        type: 'number',
      },
      {
        key: 'lng',
        label: 'appointment.lng.label',
        type: 'number',
      }
    ]
  },
  created: {
    type: 'composite',
    label: 'created',
    key: 'created',
    layout: 'rows',
    children: [
      {
        key: 'createdAt',
        label: 'global.createdAt.label',
        type: 'date',
        time: true
      },
      {
        key: 'createdByUserEmail',
        label: 'global.createdBy.label',
        type: 'email',
        cut: true
      }
    ]
  },
  updated: {
    type: 'composite',
    label: 'updated',
    layout: 'rows',
    key: 'updatedAt',
    columnName: 'updated',
    children: [
      {
        type: 'date',
        key: 'updatedAt',
        label: 'global.updatedAt.label',
        time: true
      },
      {
        type: 'email',
        key: 'updatedByUserEmail',
        label: 'global.updatedBy.label',
        cut: true,
        columnName: 'updatedByUserEmail'
      }
    ]
  }
}

export default {
  path: 'appointments',
  label: 'menu.crm.appointment',
  component: Appointments,
  fields: omit(appointmentFields, 'role'),
  presets: [
    {
      id: 'status_canceled',
      name: 'Canceled',
      filters: [
        {
          id: 'status',
          label: 'global.status.label',
          mask: [''],
          key: 'status',
          prefix: 'appointment.status',
          value: ['cancel']
        }
      ]
    }
  ],
  permissions: {
    entity: 'web.admin.ui.crm.appointments2',
    types: ['READ']
  }
}
