import styled from 'styled-components'

const AlertContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 0.75rem 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
`

export function AlertMessage({message}) {
  return <AlertContainer>{message}</AlertContainer>
}

export default AlertMessage
