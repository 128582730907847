import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import {I18nextProvider} from 'react-i18next'
import {Security} from '@okta/okta-react'

import rawConfig from './config/appConfig'
import {getCountryConfig} from '../config/country'
import appStore from './store'
import ThemeProvider from './style/theme'
import {createTranslation} from './config/translation'
import Layout from './layout'
import {trackPage} from './utils/segment'
import {getOktaAuthInstance, restoreOriginalUri} from './utils/okta'
import {history} from '../../history.js'

const config = rawConfig(getCountryConfig())
const store = appStore(config)
const i18n = createTranslation(config)
const oktaAuth = getOktaAuthInstance(config)

trackPage()

history.listen(() => {
  trackPage()
})

if (typeof window !== 'undefined') {
  const urlParams = new URLSearchParams(window.location.search)
  const googleLogin = urlParams.get('googleLoginSuccess')
  if (googleLogin === 'true' && window?.opener?.location) {
    const redirectUrl = new URL(window.location.href)
    redirectUrl.searchParams.delete('googleLoginSuccess')
    window.opener.location.href = redirectUrl.toString()
    window.close()
  }
}

const App = () => {
  return (
    <Provider store={store}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <I18nextProvider i18n={i18n}>
          <Router history={history}>
            <ThemeProvider>
              <Security
                oktaAuth={oktaAuth}
                restoreOriginalUri={restoreOriginalUri}
              >
                <Layout />
              </Security>
            </ThemeProvider>
          </Router>
        </I18nextProvider>
      </Security>
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))

console.log('app loaded', new Date())
