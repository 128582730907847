export const getUserName = (authReducer) => {
  const {isAuthenticated} = authReducer
  if (!isAuthenticated) {
    return null
  } else {
    const {username, email, user} = isAuthenticated
    const {firstname = '', lastname = ''} = user || {}
    return username || email || `${firstname}  ${lastname}`.trim()
  }
}

export const getUserEmail = (authReducer) => {
  const {isAuthenticated} = authReducer
  if (!isAuthenticated) {
    return ''
  } else {
    const {user = {}, email: extEmail} = isAuthenticated
    const {email = ''} = user
    return String(extEmail || email).trim()
  }
}
