import {useCallback} from 'react'
import {compose} from 'redux'
import {translate} from 'react-i18next'
import Button from '@material-ui/core/Button'

import {useOktaAuth} from '@okta/okta-react'
import {withStyles} from '@material-ui/core'
import {getReturnUrl} from '../../../utils/renderingHelpers'

const styles = () => ({
  containerDiv: {
    width: '100%'
  },
  button: {
    backgroundColor: '#012a7a',
    color: '#fff',
    width: '100%',
    height: '36px',
    fontSize: '16px',
    outline: 'none',
    border: 'none',
    boxShadow: '2px 2px 2px 1px #888',
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '8px auto 0',

    '&:hover': {
      backgroundColor: '#012a5a',
      boxShadow: 'inset 0px 0px 6px 0px #222'
    }
  },
  infoBox: {
    backgroundColor: '#d9edf7',
    borderColor: '#bcdff1',
    color: '#31708f',
    margin: '0 auto',
    padding: '0.75rem 1.5rem',
    marginBottom: '1rem',
    border: '1px solid transparent',
    borderRadius: '0.25rem',
    display: 'block',
    marginTop: '8px',
    textAlign: 'center',
    fontSize: '1em'
  }
})
export function OktaLoginButton({t, classes}) {
  const {oktaAuth} = useOktaAuth()

  const login = useCallback(async () => {
    const redirectUrl = getReturnUrl()
    await oktaAuth.signInWithRedirect({originalUri: redirectUrl})
  }, [oktaAuth])

  return (
    <div className={classes.containerDiv}>
      <Button onClick={login} className={classes.button}>
        {t('login.button.login.okta')}
      </Button>
      <div className={classes.infoBox}>{`* ${t(
        'global.okta.login.warning.message'
      )}`}</div>
    </div>
  )
}

export default compose(withStyles(styles), translate())(OktaLoginButton)
