import {Component} from 'react'
import {compose} from 'redux'
import {withRouter} from 'react-router'
import {withOktaAuth} from '@okta/okta-react'
import {connect} from 'react-redux'

import {
  redirectReset,
  authenticateOktaToken,
  callOktak2Login,
  callGoogleK2Login,
  authenticateGoogleToken
} from '../../store/auth/actions'
import {
  checkUserPermissions,
  checkUserManagerPermission,
  hasUserPermissions
} from '../../store/auth/selectors'
import {error} from '../../store/global/actions'
import {registerHistory} from '../../utils/history'

import {getHiddenPages} from '../../store/config/selectors'
import {PAYSLIP_PERMISSION} from '../../store/crmUserGroups/permissionsConfig'
import {getGoogleOAuthTokens} from '../../utils/authToken'
import {API_STATUS} from '../../appConstants'

export class Auth extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasRedirectedFromPayslips: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !nextProps.isAuthenticated &&
      nextProps.isAuthenticated !== prevState.hasRedirectedFromPayslips
    ) {
      return {hasRedirectedFromPayslips: false}
    }

    return null
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.location.pathname !== nextProps.location.pathname ||
      this.props.isAuthenticated !== nextProps.isAuthenticated ||
      nextProps.redirect !== this.props.redirect ||
      nextProps.hasUserInfo !== this.props.hasUserInfo ||
      nextProps.authState !== this.props.authState ||
      (nextProps.authState &&
        this.props.authState &&
        nextProps.authState.isAuthenticated !==
          this.props.authState.isAuthenticated)
    )
  }

  handleUnauthorizedAccess = () => {
    this.props.error({
      message: 'global.errors.invalidToken',
      action: null
    })

    const loginPath = location.pathname
      ? `/login?returnUrl=${location.pathname}${location.search}`
      : '/login'

    this.props.history.push(loginPath)
  }

  isUnauthorizedAccess = () => {
    return (
      this.props.isAuthenticated === false &&
      this.props.location.pathname.indexOf('login') === -1
    )
  }

  componentDidMount() {
    registerHistory(this.props.history)

    const loginPath = window.location.pathname.includes('k2')
      ? 'k2-login'
      : 'login'

    //If page gets redirected from google
    const {accessToken: googleAccessToken} = getGoogleOAuthTokens()
    if (googleAccessToken) {
      if (loginPath === 'k2-login') {
        this.props.callGoogleK2Login()
        this.props.history.push('/k2-login/callback')
      } else {
        this.props.authenticateGoogleToken()
      }
    } else if (this.props.oktaAuth.isLoginRedirect()) {
      // CUSTOM TOKEN CAPTURING FROM OKTA
      // when user is redirected back to /login/callback page from okta login page
      // this pieceof code will detect if the user has been redirected
      // and then capture the token and then proceeds to authenticate with auth service
      this.props.oktaAuth.token
        .parseFromUrl()
        .then((result) => {
          this.props.oktaAuth.tokenManager.setTokens(result.tokens)
          if (loginPath === 'k2-login') {
            this.props.callOktak2Login({token: result.tokens})
          } else {
            const {idToken, claims, clientId} = result.tokens.idToken
            this.props.authenticateOktaToken(idToken, clientId, claims.nonce)
          }
        })
        .catch((err) => {
          if (err && err.errorSummary) {
            this.props.history.push(
              `/${loginPath}?em=${decodeURIComponent(err.errorSummary)}`
            )
          }
        })
    } else if (
      this.isUnauthorizedAccess() &&
      !this.props.authState?.isAuthenticated
    ) {
      this.handleUnauthorizedAccess()
    }
  }

  getMenuItemsPath = () => {
    const {items, hiddenFields, user} = this.props

    const userHiddenFields =
      typeof user !== 'undefined' && user.menuItems
        ? user.menuItems.hiddenFields || []
        : []

    return items
      .reduce((acc, item) => {
        if (
          hiddenFields.includes(item.key) ||
          userHiddenFields.includes(item.key) ||
          ['inspectionReports'].includes(item.path)
        ) {
          return acc
        }

        if (item.permissions && !this.props.hasPermissions(item.permissions)) {
          return acc
        }

        if (item.subItems) {
          return [
            ...acc,
            ...item.subItems.map((subItem) => `${item.path}/${subItem.path}`)
          ]
        }

        return [...acc, item.path]
      }, [])
      .filter((item) => !item.includes(':'))
  }

  componentDidUpdate(prevProps) {
    let path = this.props.user.homepage

    if (this.props.isAuthenticated && this.props.redirect !== false) {
      this.props.redirectReset()

      if (
        path.includes('/payslips') &&
        !this.props.hasPermissions({
          entity: PAYSLIP_PERMISSION,
          types: ['READ']
        })
      ) {
        const menuItems = this.getMenuItemsPath() || []
        path = menuItems[0] || path

        if (
          path === '/payslips' &&
          !prevProps.location.pathname.includes('login')
        ) {
          path = prevProps.location.pathname
        }

        this.setState({
          hasRedirectedFromPayslips: true
        })
      }

      if (this.props.redirect) {
        path = this.props.redirect
      }

      return this.props.history.push(path)
    }

    // similar code as in componentDidMount
    // when user is already logged in OKTA: user will simply redirect from /login to /login/callback
    // so componentDidUpdate will trigger with change in the authState
    // and proceeds to authenticate the okta token
    if (
      this.isUnauthorizedAccess() &&
      this.props.authState &&
      this.props.googleLoginProgress === API_STATUS.STALE
    ) {
      if (this.props.authState.isAuthenticated) {
        this.props.authenticateOktaToken()
      } else {
        this.handleUnauthorizedAccess()
      }
    }

    // this check is added because it may happen
    // user fails on /login/callback (i.e okta authenticated but still not in fcg system)
    // in that case the above if cause will still not work
    if (this.props.redirect && this.props.redirect.startsWith('/login')) {
      this.props.redirectReset()
      this.props.history.push('/login')
    }

    if (
      this.props.hasUserInfo &&
      this.props.history.location.pathname === '/payslips' &&
      !this.state.hasRedirectedFromPayslips &&
      !this.props.hasPermissions({
        entity: PAYSLIP_PERMISSION,
        types: ['READ']
      })
    ) {
      const menuItems = this.getMenuItemsPath() || []
      path = menuItems[0] || path

      this.setState({
        hasRedirectedFromPayslips: true
      })

      return this.props.history.push(path)
    }
  }

  render() {
    if (this.isUnauthorizedAccess() === true) {
      return null
    }

    return this.props.children
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  redirect: state.authReducer.redirect,
  hasPermissions: (permissions) => checkUserPermissions(state, permissions),
  hasUserManagerPermission: checkUserManagerPermission(state),
  items: state.global.menuItems.allFields[0],
  hiddenFields: getHiddenPages(state),
  hasUserInfo: hasUserPermissions(state),
  googleLoginProgress: state.authReducer.googleLoginProgress
})

const mapDispatchToProps = (dispatch) => ({
  redirectReset: () => dispatch(redirectReset()),
  error: (payload) => dispatch(error(payload)),
  authenticateOktaToken: (token, clientId, nonce) =>
    dispatch(authenticateOktaToken(token, clientId, nonce)),
  callOktak2Login: (payload) => dispatch(callOktak2Login(payload)),
  callGoogleK2Login: (payload) => dispatch(callGoogleK2Login(payload)),
  authenticateGoogleToken: () => dispatch(authenticateGoogleToken())
})

export default compose(
  withOktaAuth,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Auth)
