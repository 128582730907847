/* global NODE_ENV */
import {toRelativeUrl, OktaAuth} from '@okta/okta-auth-js'

export function getOktaAuthInstance({url, okta}) {
  const {domain = '/', clientId = ''} = okta || {}

  const loginPath = window.location.pathname.includes('k2')
    ? 'k2-login'
    : 'login'

  url = `admin2.${url}`

  return new OktaAuth({
    clientId: `${clientId}`,
    issuer: `https://${domain}`,
    redirectUri: `https://${url}/${loginPath}/callback/`,
    postLogoutRedirectUri: `https://${url}/${loginPath}/`,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    tokenManager: {
      autoRenew: true
    }
  })
}

export function restoreOriginalUri(_oktaAuth, originalUri) {
  window.location.replace(
    toRelativeUrl(originalUri || '/', window.location.origin)
  )
}
