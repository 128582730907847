import merge from 'lodash/merge'

export const appointmentSchema = {
  id: '',
  leadId: '',
  bookingId: '',
  date: '',
  time: '',
  placeId: '',
  inspectionPointCity: '',
  inspectionPointName: '',
  status: '',
  rejectionReasons: '',
  cancellationReason: '',
  comments: '',
  bookingSource: '',
  callStatus: '',
  cancelledAt: null,
  type: '',
  assignedTo: null,
  assignedToName: null,
  slot: '',
  appointmentAddress: '',
  participants: [],
  purchaseCoordinator: '',
  purchaseCoordinatorId: '',
  purchaseCoordinatorPhone: ''
}

export const state = {
  form: {...appointmentSchema},
  editState: {error: false, success: false, pending: false},
  cancelState: {pending: false},
  loadingSlots: false,
  options: {
    appointmentSlots: [],
    appointmentType: []
  },
  list: {
    count: 0,
    page: 1,
    limit: 25,
    fetching: false,
    sort: [
      {field: 'date', order: 'DESC'},
      {field: 'time', order: 'DESC'}
    ],
    data: [],
    fields: []
  },
  carLink: null,
  isCarCreatedDialogBoxVisible: false
}

export const getInitialState = (preferences = {}) => {
  const setup = merge(state, preferences)

  return {
    ...setup,
    list: {
      ...setup.list,
      fields: Object.values(setup.list.fields)
    }
  }
}
