import {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'
import {Button} from '@material-ui/core'
import styled from 'styled-components'
import {withRouter} from 'react-router'
import cookies from 'browser-cookies'

import {UserConsumer} from '../../components/providers/withAccessManagement'
import InternalLogin from './InternalLogin'
import CustomSnackbar from '../../components/Snackbar'
import OktaLoginButton from './components/OktaLoginButton'
import AlertMessage from './components/AlertMessage'
import {manualLogin} from '../../store/auth/actions'
import {translate} from 'react-i18next'
import {getCountryConfig} from '../../../config/country'
import './Login.styl'
import {getCountryName} from '../../store/auth/selectors'
import withConfig from '../../components/providers/withConfig'
import {getGoogleOAuthUrl} from '../../api/auth/requests'
import {getReturnUrl} from '../../utils/renderingHelpers'

let returnUrlGlobal = null

const LogoWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  width: 143px;
  height: 27px;
`

const CountryName = styled.span`
  color: #7f9799;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.25px;
  text-align: center;
`

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 8px !important;
`
export class LoginBase extends Component {
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const returnUrl = urlParams.get('returnUrl')

    if (returnUrl) {
      window.sessionStorage.setItem('returnUrl', returnUrl)
    } else {
      window.sessionStorage.removeItem('returnUrl')
    }

    this.checkHomePage()
  }

  checkHomePage = () => {
    const returnUrl = window.sessionStorage.getItem('returnUrl')
    const homepage = this.props.user.homepage || '/crm/home'

    if (returnUrl) {
      returnUrlGlobal = returnUrl
    }

    if (homepage !== '/login') {
      this.props.history.replace(returnUrlGlobal || homepage)
      returnUrlGlobal = null
      window.sessionStorage.removeItem('returnUrl')
    }
  }

  getOktaError = () => {
    const urlParams = new URLSearchParams(window.location.search)

    return urlParams.has('em') ? decodeURIComponent(urlParams.get('em')) : ''
  }

  handleGoogleLogin = (type = 'global') => {
    cookies.set('google-client-type', type, {samesite: 'None', secure: true})
    const urlParams = new URLSearchParams(window.location.search)
    const returnUrl = urlParams.get('returnUrl')
    window.sessionStorage.setItem('googleRedirect', returnUrl || '/')
    getGoogleOAuthUrl({redirectUrl: returnUrl || '/'}).then((res) => {
      const {authUrl} = res?.data || {}
      if (authUrl) {
        window.open(authUrl)
      }
    })
  }

  render() {
    const {t} = this.props
    const oktaError = this.getOktaError()

    if (!this.props.isAuthenticated) {
      return (
        <div className='Login Login--center'>
          <LogoWrapper>
            <Logo src='/public/images/olxautos.png' />
            <CountryName>{this.props.countryName}</CountryName>
          </LogoWrapper>
          {oktaError ? <AlertMessage message={oktaError} /> : null}
          <StyledButton
            variant='raised'
            color='primary'
            onClick={() => this.handleGoogleLogin('local')}
          >
            {t('login.button.login.google')} (Local)
          </StyledButton>
          <StyledButton
            variant='raised'
            color='primary'
            onClick={() => this.handleGoogleLogin('global')}
          >
            {t('login.button.login.google')}
          </StyledButton>
          {this.props.okta ? <OktaLoginButton /> : null}
          {getCountryConfig().internalLogin !== false && (
            <InternalLogin
              onSubmit={this.props.manualLogin}
              {...this.props.loginForm}
            />
          )}
          <CustomSnackbar />
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = ({authReducer, config}) => {
  const {isAuthenticated, loginForm} = authReducer
  return {
    isAuthenticated,
    loginForm: loginForm.internal,
    countryName: getCountryName(),
    config
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    manualLogin: (data) => dispatch(manualLogin(data))
  }
}

const LoginForm = (props) =>
  UserConsumer(translate()(withRouter(LoginBase)), props)

export default compose(
  translate(),
  connect(mapStateToProps, mapDispatchToProps)
)(withConfig(['okta'], LoginForm))
