const dealerDetails = require('./dealerManagement/in')
const crmDetails = require('./CRM/in')
const bulkuploadDetails = require('./bulkUpload/in')
const carDataDetails = require('./carData/in')
const locationDetails = require('./locationManagement/in')
const payslipDetails = require('./payslips/in')
const carOwnership = require('./carOwnership/in')
const businessPartnersDetails = require('./businessPartnersDetails/in')

module.exports = {
  url: 'cashmycar.olx.in',
  isMTCMSEnabled: true,
  siteCode: 'olxin',
  brand: 'olx-autos',
  apiDomain: 'api.olx.in',
  name: 'India',
  countryCode: 'IN',
  theme: 'india',
  language: {
    selected: 'en-in',
    options: [{value: 'en-in', label: 'EN'}]
  },
  locales: ['en-in'],
  currency: {code: 'INR', symbol: 'Rs.'},
  number: {decimalSep: '.', unitSep: ',', units: [10000000, 100000, 1000]},
  fallbackLocale: 'en',
  dateFormat: 'DD-MM-YYYY',
  phone: {length: [10], code: 91},
  useExactPhoneNumberSearch: true,
  phoneConfiguration: {codePrefix: '0091', minLength: 10},
  referralCodeType: 'input',
  googleKey:
    '717103911078-j59f6l85gpuf1vveq527rujle0qncaim.apps.googleusercontent.com',
  googleGlobalKey:
    '543555054723-hj1p9vc3agdrrgmvr94h79j2617is0l0.apps.googleusercontent.com',
  okta: {
    domain: 'olxgroup.okta-emea.com',
    clientId: '0oa83ik6e7Xa5ZwqQ0i7'
    // FOR dev purpose
    // clientId: '0oa5787n1dr9m2V0y5d7',
    // domain: 'dev-36052658.okta.com'
  },
  loginInternalPageAvailable: true,
  internalLogin: false,
  disabledBookingSources: ['Web Lead'],
  readOnlyPaths: ['dealers'],
  dealerDetails,
  crmDetails,
  carDuplicatedCheckField: 'vin',
  auctionPreviewOffset: 60,
  bulkuploadDetails,
  carDataDetails,
  locationDetails,
  validation: {
    carCreate: {
      make: {
        required: true
      },
      model: {
        required: true
      },
      year: {
        regex: '^[0-9]*$'
      },
      mileage: {
        regex: '^[0-9]*$'
      },
      price: {
        required: true,
        regex: '^[1-9]\\d*$'
      }
    }
  },
  hiddenPages: [
    'locations',
    'bulkUpload',
    'bulkUploadV2',
    'documentManagement',
    'carData',
    'tms'
  ],
  hiddenLeadDropdown: ['carId'],
  hasSelfInspection: false,
  payslipDetails,
  carOwnership,
  leadsAdId: {
    link: 'https://www.olx.in/item',
    disabled: false
  },
  callingCode: '+91',
  businessPartnersDetails: businessPartnersDetails.bpRecordFormConfig,
  validations: businessPartnersDetails.regex,
  bpAddFormConfig: businessPartnersDetails.bpAddFormConfig,
  bpvalueBasedRequiredConditions:
    businessPartnersDetails.valueBasedRequiredConditions,
  bpSearchByOptions: businessPartnersDetails.bpSearchByOptions,
  showDialerToolbar: true,
  genesysIntegrationScriptSrc:
    'https://olx.cexlab.com/javascripts/genesys-integration.js',
  pages: {
    contactDetail: {
      header: {
        phone: {
          canLink: true,
          action: []
        }
      }
    }
  },
  categoryId: 84,
  useGrootApi: ['make', 'model', 'year', 'variant'],
  hasLogsInContactDetail: true,
  locationNeededForAip: true,
  fetchLocationFromCountryOptions: true,
  fetchPriceQuoteFromAIP: true,
  disableOtherFreeTextInDropdown: true,
  isNotificationsEnabled: false
}
